import './app.scss';
import 'lazysizes';
import htmx from 'htmx.org';
// import 'intersection-observer';

// if you need react, just enable it by commenting in this line
// import react from './react/index';

(function () {
    // if you need react, just enable it by commenting in this line
    // react.init();

    const accordionSections = document.querySelectorAll('.accordion-section') as NodeListOf<HTMLElement>;
    if (accordionSections.length) {
        import('./ts/accordion').then((accordion) => {
            accordionSections.forEach(function (section) {
                accordion.default.init(section);
            });
        });
    }

    const accordionImageSections = document.querySelectorAll('.accordion-image') as NodeListOf<HTMLElement>;
    if (accordionImageSections.length) {
        import('./ts/accordion-image').then((accordionImage) => {
            accordionImageSections.forEach(function (section) {
                accordionImage.default.init(section);
            });
        });
    }

    const logoSliders = document.querySelectorAll('.logo-slider') as NodeListOf<HTMLElement>;
    if (logoSliders.length) {
        logoSliders.forEach((section) => {
            import('./ts/logo-slider').then((logoSlider) => {
                logoSlider.default.init(section);
            });
        });
    }

    const menu = document.getElementById('header-navigation');
    if (menu) {
        import('./ts/header').then((header) => {
            header.default.init();
        });
    }

    const aosElements = document.querySelectorAll('[data-aos]');
    if (aosElements.length) {
        import('./ts/aos').then((aos) => {
            aos.default.init();
        });
    }

    // Init all swiper if there are some
    const allSwiper = document.querySelectorAll('.swiper-container') as NodeListOf<HTMLElement>;
    if (allSwiper.length) {
        import('./ts/swiper').then((swiper) => {
            allSwiper.forEach(function (swiperElement) {
                if (!swiperElement.dataset.swiperOptions) return;
                // Set swiper element and swiper options from data-attribute
                swiper.default.init(swiperElement, JSON.parse(swiperElement.dataset.swiperOptions));
            });
        });
    }

    // no element request because element will be generated via js, only used once
    import('./ts/cookie-banner').then((cookieBanner) => {
        cookieBanner.default.init();
    });

    setTimeout(() => {
        const blogMasonryGrid = document.querySelectorAll('.post-mansonry-grid');
        if (blogMasonryGrid.length) {
            import('./ts/blog-masonry').then((blogMasonry) => {
                blogMasonry.default.init(blogMasonryGrid);
            });
        }
    }, 500);
})();
